<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{ $t('payable_cheques.payable_cheques')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>
            <div class="card-body">

                    <div class="card-body p-0">
                        <div class="form-group row">


                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.amount')}}<span class="text-danger">*</span></label>
                                <input type="number" v-model="data.amount" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.amount[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.currency')}}<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                                        <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                                    </select>
                                    <div class="input-group-prepend">
                                        <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                                    </div>
                                </div>
                                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.currency_id[0] }}
                                </span>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('exchange_rate')}}</label>
                                <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.exchange_rate[0] }}
                                </span>
                            </div>
                            
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.bank')}}</label>
                                <div class="input-group">
                                    <multiselect v-model="bank"
                                        :placeholder="$t('payable_cheques.bank')"
                                        label="name"
                                        track-by="id"
                                        :options="banks"
                                        :multiple="false"
                                        :taggable="false"
                                        :show-labels="false"
                                        :show-no-options="false"
                                        :show-no-results="false">
                                    </multiselect>
                                    <div class="input-group-prepend">
                                        <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                                    </div>
                                </div>
                                <span v-if="validation && validation.bank_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.bank_id[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.cheque_book_number')}}<span class="text-danger">*</span></label>
                                <select name="" id="book_id" v-model="data.book_id" class="custom-select" :class="validation && validation.book_id ? 'is-invalid' : ''">
                                    <option v-for="row in books" :value="row.id" :key="row.id">{{ row.bank_booking_no }}</option>
                                </select>
                                <span v-if="validation && validation.book_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.book_id[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.cheques_no')}}<span class="text-danger">*</span></label>
                                <input type="number" v-model="data.cheques_no" class="form-control" :class="validation && validation.cheques_no ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.cheques_no" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.cheques_no[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.issue_date')}}</label>
                                <input type="date" v-model="data.issue_date" class="form-control" :class="validation && validation.issue_date ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.issue_date" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.issue_date[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.due_date')}}</label>
                                <input type="date" v-model="data.due_date" class="form-control" :class="validation && validation.due_date ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.due_date" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.due_date[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('purchases_orders.branch')}}</label>
                                <!-- <select name="" id="branch_id" v-model="data.branch_id" class="custom-select" :class="validation && validation.branch_id ? 'is-invalid' : ''">
                                    <option v-for="row in branches" :value="row.id" :key="row.id">{{ row.name }}</option>
                                </select> -->
                                <div class="input-group">
                                    <multiselect v-model="branch"
                                        :placeholder="$t('payable_cheques.branch')"
                                        label="name"
                                        track-by="id"
                                        :options="branches"
                                        :multiple="false"
                                        :taggable="false"
                                        :show-labels="false"
                                        :show-no-options="false"
                                        :show-no-results="false">
                                    </multiselect>
                                    <div class="input-group-prepend">
                                        <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                                    </div>
                                </div>
                                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.branch_id[0] }}
                                </span>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.received_account')}}</label>
                                <input type="number" v-model="data.received_account" class="form-control" :class="validation && validation.received_account ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.received_account" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.received_account[0] }}
                                </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.cheques_name')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.cheques_name" class="form-control" :class="validation && validation.cheques_name ? 'is-invalid' : ''"/>
                                <span v-if="validation && validation.cheques_name" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.cheques_name[0] }}
                                </span>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('accounts')}}</label>
                                <!-- <treeselect
                                        :options="accounts"
                                        :load-options="loadOptions"
                                        :multiple="false"
                                        :value="data.account_id"
                                        @input="updateValue($event)"
                                        :searchable="true"
                                        :disabled="isDisabled"
                                        :show-count="true"
                                        :no-children-text="$t('No_sub_options')"
                                        :no-options-text="$t('No_options_available')"
                                        :no-results-text="$t('No_results_found')"
                                        :placeholder="$t('Select')">
                                </treeselect> -->
                                <div class="input-group">
                                    <multiselect
                                        v-model="account"
                                        :placeholder="$t('accounts')"
                                        label="label"
                                        track-by="id"
                                        :options="accounts"
                                        :multiple="false"
                                        :taggable="false"
                                        :show-labels="false"
                                        :show-no-options="false"
                                        :show-no-results="false"
                                        @search-change="getAccounts($event)">
                                    </multiselect>
                                    <div class="input-group-prepend">
                                        <a class="btn btn-primary" href="/accounting/chart-accounts" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                                    </div>
                                </div>
                                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('accounts') }}.</span>
                            </div>
                            
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.description')}}</label>
                                <textarea name="" id="notes" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.description[0] }}
                                </span>
                            </div>
                            
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.status')}}</label>
                                <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                                    <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                                </select>
                                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('payable_cheques.attachment')}}</label>
                                <upload-file
                                        @file="listenerAttachment"
                                        :inner-id="'attachment'"
                                        :placeholder="$t('payable_cheques.upload_attachment')"
                                        :upload="dir_upload"
                                        :start-link="'base'"
                                        v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                                </upload-file>
                                <span v-if="validation && validation.attachment" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.attachment[0] }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer pr-0 pl-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                                <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import {mapGetters, mapState} from "vuex";
    import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

    export default {
        name: "form-cheques-payable-cheques",
        data() {
            return {
                mainRoute: 'chequescycle/payable-cheques',
                mainRouteDependency: 'base/dependency',
                dir_upload: 'cheques',

                data: {
                    amount: null,
                    book_id: null,
                    cheques_no: null,
                    bank_id: null,
                    branch_id: null,
                    currency_id: null,
                    status: 1,
                    received_account: null,
                    cheques_name: null,
                    issue_date: null,
                    due_date: null,
                    description: null,
                    attachment: null,
                    account_id: null,
                    exchange_rate : null,

                },
                idEditing: this.$route.params.id ? this.$route.params.id : null,
                isEditing: false,
                reloadUploadAttachment: true,
                attachment_url: null,
                accounts : [],
                account:null,
                isDisabled : false,

                currencies: [],
                branches: [],
                banks: [],
                books: [],
                status_list: [],
                bank: null,
                branch: null,
                validation: null,

            };
        },
        computed: {
            ...mapState({user_personal_info: state => state.profile.user_personal_info}),
            ...mapGetters(["currentUserPersonalInfo"]),

        },
        watch : {

            account : function (val) {
                if (val) {
                    this.data.account_id = val.id;
                } 
            },
            branch: function (val) {
                if (val) {
                    this.data.branch_id = val.id;
                } 
            },
            bank: function (val) {
                if (val) {
                    this.data.bank_id = val.id;
                } 
            },
            "data.currency_id": function (val) {
                if (val) {
                    this.getExchangeRate(val);
                } else {
                    this.data.exchange_rate = null;
                }
            },
        },
        methods: {
          ...timeZoneStructure,
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(`${this.mainRoute}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.$router.push({name: 'payable-cheques.index'});
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            update() {
                ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$router.push({name: 'payable-cheques.index'});
                        this.$successAlert(response.data.message);
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },

            async getData() {
                this.reloadUploadAttachment = false;
                await ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
                    this.isEditing = true;
                    this.data.amount = response.data.data.amount;
                    this.data.book_id = response.data.data.book_id;
                    this.data.cheques_no = response.data.data.cheques_no;
                    this.bank = response.data.data.bank;
                    this.branch = response.data.data.branch;
                    
                    this.data.bank_id = response.data.data.bank_id;
                    this.data.branch_id = response.data.data.branch_id;

                    this.data.exchange_rate = response.data.data.exchange_rate;

                    this.data.currency_id = response.data.data.currency_id;
                    this.data.status = response.data.data.status;
                    this.data.received_account = response.data.data.received_account;
                    this.data.cheques_name = response.data.data.cheques_name;
                    this.data.issue_date = response.data.data.issue_date;
                    this.data.due_date = response.data.data.due_date;
                    this.data.description = response.data.data.description;
                    this.data.attachment = response.data.data.attachment;
                    this.attachment_url = response.data.data.attachment_url;
                    this.data.account_id = response.data.data.account_id;
                    this.account = response.data.data.account;
                    if(!response.data.data.account_id){
                        this.getDefaultAccount();
                    }
                    this.reloadUploadAttachment = true;
                });
            },

            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },

            getDefaultCurrency() {
                ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
                    this.data.currency_id = response.data.data.id;
                });
            },
            getBranches() {
                ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
                    this.branches = response.data.data;
                });
            },

            getBanks() {
                ApiService.get(`${this.mainRouteDependency}/banks`).then((response) => {
                    this.banks = response.data.data;
                });
            },

            getBooks() {
                ApiService.get(this.mainRouteDependency + "/books").then((response) => {
                    this.books = response.data.data;
                });
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/cheques_status").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            listenerAttachment(event) {
                if (event) {
                    this.attachment_url = event.pathDB;
                    this.data.attachment = event.name;
                }
            },
         
            // getAccounts() {
            //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'payable_cheque_account'}}).then((response) => {
            //         this.accounts = response.data.data;
            //     });
            // },
            getAccounts(filter) {
                if (filter && filter.length >= 3)
                return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`,{params:{name: filter}}).then((response) => {
                    this.accounts = response.data.data;
                });
            },
            getDefaultAccount() {
                ApiService.get(`${this.mainRouteDependency}/get_default_account/payable_cheque_account`).then((response) => {
                    // this.data.account_id = response.data.data.id;
                    this.account = response.data.data;
                });
            },
            loadOptions() {
            },
            updateValue(value) {
                this.data.account_id = value;
            },
            getExchangeRate(id) {
                ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
                    this.data.exchange_rate = response.data.data.initial_val;
                });
            },

            defaultDataForUser(){
                ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
                    this.data.currency_id = response.data.data.currency_id;
                    this.branch = response.data.data.branch;
                    this.data.branch_id = response.data.data.branch_id;
                });
            }

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payable_cheques"),route:'/cheques-cycle/payable-cheques'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


            this.getCurrencies();
            // this.getDefaultCurrency();
            this.getBranches();
            this.getBanks();
            this.getBooks();
            this.getStatusList();
            this.getAccounts();
            if(!this.data.account_id){
                this.getDefaultAccount();
            }
            if (this.idEditing) {
              this.getData();
            }else {
              this.defaultDataForUser();
              // this.data.issue_date = new Date().toISOString().substr(0, 10);
              //   this.data.due_date = new Date().toISOString().substr(0, 10);

              this.getTimeZone().then((res)=>{
                this.data.issue_date = res;
                this.data.due_date = res;
              });
            }

        },
    };
</script>